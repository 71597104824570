<template>
  <a-range-picker
    v-model="date"
    valueFormat="YYYY-MM-DD"
    @change="onChange"
  >
    <a-icon
      slot="suffixIcon"
      type="calendar"
    />
  </a-range-picker>
</template>
<script>
export default {
  props: {
    start: String,
    end: String
  },
  data() {
    return {
      date: null
    };
  },
  watch: {
    start() {
      this.setValue();
    },
    end() {
      this.setValue();
    }
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      const { start, end } = this;
      this.date = [start, end];
    },
    onChange(date, arr) {
      this.$emit("update:start", arr[0]);
      this.$emit("update:end", arr[1]);
    }
  }
};
</script>
<style>
</style>
